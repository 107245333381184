





























import Vue from 'vue';
import { StripeCheckout } from 'vue-stripe-checkout';

import BackButton from '@/components/ui/BackButton.vue';
import CartActions from '@/components/cart/CartActions.vue';
import CartSummary from '@/components/cart/CartSummary.vue';
import CartTable from '@/components/cart/CartTable.vue';
import { ICartData, ICartItem } from '@/components/cart/types';
import CartFooter from '@/components/cart/CartFooter.vue';
import { mapState } from 'vuex';
import { CartCheckoutItem, KnoxService } from '@/services/knox.service';

interface CartState {
  items: ICartItem[];
  cart: ICartData;
}

interface StripeCheckoutRef extends Element {
  redirectToCheckout?: () => void;
  sessionId: string;
}

export default Vue.extend({
  name: 'Cart',
  components: {
    BackButton,
    CartActions,
    CartSummary,
    CartTable,
    CartFooter,
    StripeCheckout,
  },
  data: () => ({
    pk: '',
    session: '',
    error: undefined as Error | undefined,
  }),
  mounted() {
    this.pk = process.env.VUE_APP_STRIPEPK;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleRemove(item: ICartItem) {
      this.$store.dispatch('removeFromCart', item);
    },
    async checkout() {
      try {
        const items: CartCheckoutItem[] = this.items.map(
          (item): CartCheckoutItem => {
            const priceId =
              process.env.NODE_ENV === 'production'
                ? item.priceId
                : item.priceIdTest;

            return {
              item: priceId,
              itemId: item._id.toString(),
              itemType: 'pack',
            };
          },
        );

        if (!items.length) {
          throw new Error('No items in cart!');
        }

        const customer = this.$store.state.user.profile.stripeCustomer;
        const sessionRef = this.$refs.sessionRef as StripeCheckoutRef;
        const { sessionId } = await KnoxService.createCartCheckoutSession(
          items,
          customer,
          this.successUrl,
          this.cancelUrl,
        );

        if (!sessionId) {
          throw new Error(
            'Error creating cart session. Try again after a few moments.',
          );
        }

        if (sessionRef && sessionId) {
          this.session = sessionId;
          sessionRef.sessionId = sessionId;

          if (sessionRef.redirectToCheckout) {
            sessionRef?.redirectToCheckout();
          }
        }
      } catch (error) {
        if (error && error instanceof Error) {
          setTimeout(() => {
            this.error = undefined;
          }, 5000);
          this.error = error;
          return;
        }
      }
    },
  },
  computed: {
    ...mapState({
      items: (state: any): ICartItem[] => state.cart.items,
      cart: (state: any): ICartData => state.cart.data,
    }),
    successUrl() {
      return `${window.location.href}?ck=true&cid={CHECKOUT_SESSION_ID}`;
    },
    cancelUrl() {
      return `${window.location.href}?ck=true`;
    },
  },
});
