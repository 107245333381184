







import Vue, { PropOptions } from 'vue';
import { ICartData } from './types';

export default Vue.extend({
  name: 'CartSummary',
  props: {
    cart: {
      type: Object,
      default() {
        return {
          subtotal: 0,
          tax: 0,
          total: 0,
        };
      },
    } as PropOptions<ICartData>,
  },
  computed: {
    formattedTotal(): string {
      return `$${this.cart.total.toFixed(2)}`;
    },
  },
});
