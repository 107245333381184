


















import Vue from 'vue';

export default Vue.extend({
  name: 'CartActions',
  props: {
    items: {
      type: Array,
    },
    error: {
      type: Error,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    onCheckout() {
      this.$emit('checkout');
      this.loading = !this.loading;
    },
  },
  watch: {
    error() {
      this.loading = false;
    },
  },
});
