











import Vue, { PropOptions } from 'vue';
import { ICartItem } from './types';

import CartItem from './CartItem.vue';

export default Vue.extend({
  name: 'CartTable',
  components: {
    CartItem,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    } as PropOptions<ICartItem[]>,
  },
});
