













import Vue, { PropOptions } from 'vue';
import { ICartItem } from './types';

export default Vue.extend({
  name: 'CartItem',
  props: {
    item: {
      type: Object,
      default() {
        return {
          _id: 0,
          name: '',
          price: 0,
          quantity: 0,
          thumbnailUrl: '',
          priceId: '',
          priceIdTest: '',
        };
      },
    } as PropOptions<ICartItem>,
  },
  methods: {
    goToItem() {
      this.$router.push(`/pack/${this.item._id}`);
    },
    handleCloseButtonClick(e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();

      this.$emit('remove', this.item._id);
    },
  },
  computed: {
    formattedTotal(): string {
      return `$${this.item.price.toFixed(2)}`;
    },
  },
});
